import { memo } from 'react'
import { NodeProps, NodesSelectStatusEnum } from '../types'
import {
  IconArrowOpenEndLine,
  IconButton,
  IconFolderLine,
  Link,
  ScreenReaderContent,
  Checkbox,
  View,
  Flex,
} from '@instructure/ui'

import { areEqual } from 'react-window'

import { FileIcon } from '../../../elements/FileIcon'

const Node = memo(
  ({
    style,
    data: { isFolder, name, link, nestingLevel, id, type },
    isOpen,
    setOpen,
    treeData: { nodesState, onSelectChange, onFileClick },
  }: NodeProps) => {
    return (
      <View
        key={id}
        borderWidth={'0 0 small 0'}
        height={'48px'}
        width={'100%'}
        style={{
          ...style,
        }}
        css={{
          alignItems: 'center',
          display: 'flex',
        }}
        padding={'0 0 0 medium'}
      >
        <div
          css={{
            width: `${nestingLevel * 2 + 2}rem`,
            flexShrink: 0,
          }}
        >
          <Checkbox
            label={<ScreenReaderContent>Select {name}</ScreenReaderContent>}
            checked={nodesState[id] === NodesSelectStatusEnum.Checked}
            indeterminate={
              nodesState[id] === NodesSelectStatusEnum.Indeterminate
            }
            onChange={(event) => onSelectChange(id, event.target.checked)}
            data-qa={`FilesTree_selectNode-${id}`}
          />
        </div>
        {isFolder && (
          <>
            <Flex as={'div'} margin={'0 small 0 0'} alignItems={'center'}>
              <IconButton
                margin={'0 small 0 0'}
                size={'small'}
                screenReaderLabel={isOpen ? 'Collapse' : 'Expand'}
                withBackground={false}
                withBorder={false}
                onClick={() => {
                  setOpen(!isOpen)
                }}
                data-qa={`FilesTree_expandNode-${id}`}
                renderIcon={
                  <IconArrowOpenEndLine
                    css={{
                      ...(isOpen && {
                        transform: 'rotate(90deg)',
                      }),
                      transition: 'all 0.2s ease-out',
                    }}
                  />
                }
              />
              <IconFolderLine />
            </Flex>
            <div
              css={{
                overflow: 'hidden',
                display: 'flex',
              }}
            >
              <Link
                data-qa={`FilesTree_file_id-${id}`}
                themeOverride={(_, { colors }) => ({
                  color: colors.textDarkest,
                  hoverColor: colors.textDarkest,
                })}
                isWithinText={false}
                onClick={() => {
                  setOpen(!isOpen)
                }}
              >
                <div
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {name}
                </div>
              </Link>
            </div>
          </>
        )}
        {!isFolder && (
          <div
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Link
              href={link}
              data-qa={`FilesTree_file_id-${id}`}
              themeOverride={(_, { colors }) => ({
                color: colors.textDarkest,
                hoverColor: colors.textDarkest,
              })}
              isWithinText={false}
              onClick={() => {
                onFileClick(id)
              }}
            >
              <View margin={'0 small 0 0'} padding={'xx-small'}>
                <FileIcon file={{ type }} colorful size={'x-small'} />
              </View>
              {name}
            </Link>
          </div>
        )}
      </View>
    )
  },
  areEqual
)

export { Node }
